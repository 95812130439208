import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Home = () => {
  useEffect(() => {
    document.title = "Wecraft";
  }, []);

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col">
        <div className="container">
          <Header />
        </div>
        {/* body */}
        <div className="flex flex-col justify-center item-center py-36 my-5 text-center text-white relative overflow-hidden">
          <img src="/images/background_home.jpg" className="absolute -z-20" />
          <div className="absolute w-full h-full bg-black opacity-70 -z-10" />
          <h1 className="text-3xl font-semibold">
            I am an indie game developer
          </h1>
          <h2 className="text-xl">
            I use an unusual approach to games that makes them interesting
          </h2>
        </div>
        {/* body */}
        <div className="flex flex-col container">
          {/* Item product */}
          <h3 className="text-xl mb-5">List of games:</h3>
          <div className="flex h-max mb-10 space-x-5">
            <a href="https://play.google.com/store/apps/details?id=com.AlexHK.BattleDetection">
              <div className="flex flex-col w-[200px]  border rounded-xl p-3 hover:bg-gray-100">
                <img
                  src={"/images/Icon512.jpg"}
                  height={150}
                  alt="Battle of detection"
                  className="rounded-md"
                />
                <p className="text-xl mt-2">Battle of detection</p>
              </div>
            </a>
            <a href="https://apps.apple.com/us/app/merge-ship-loop/id6741926698">
              <div className="flex flex-col w-[200px]  border rounded-xl p-3 hover:bg-gray-100">
                <img
                  src={"/images/Ship.png"}
                  height={150}
                  alt="Battle of detection"
                  className="rounded-md"
                />
                <p className="text-xl mt-2">Merge Ship Loop</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
